.topnav {
    background: var(--neutral);
    width: 100vw;
    height: var(--nav-height);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    position:fixed;
    top: 0;
    z-index:1000;
}

.subnav {
    background: var(--neutral);
    height: calc(var(--nav-height) - 10px);
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    position:fixed;
    top: calc(var(--nav-height) - 20px);
    z-index:1000;
}

.nav-spacer {
    height: var(--nav-height);
}

.topnav .smallName {
    font-size: calc(var(--nav-height) / 2);
    font-weight: 550;
    letter-spacing: 0em;
    text-align: center;
    color: var(--secondaryColor);

    cursor: pointer;
    text-decoration: none;

    z-index:1001;
}

.topnav .page-title {
    font-size: calc(var(--nav-height) / 2);
    font-weight: 550;
    letter-spacing: 0em;
    text-align: center;
    color: var(--secondaryColor);

    text-decoration: none;

    position: fixed;
    left:50%;
    transform: translate(-50%,0);
    z-index:1001;
}