@font-face {
  font-family: BeVietnamPro;
  src: url(assets/Be_Vietnam_Pro/BeVietnamPro-Medium.ttf);
}

:root {
  --mainColor: #2E534B;
  --mainColorLight: #7DCFB6;
  --secondaryColor: #F6F4F4;
  --tertiaryColor: #530C0C;
  --neutral: #434648;
  --highlight: #FD9072;

  --scrollTime: 0.2s;

  --nav-margin: calc(2vw);
  --margin: calc(20vw);

  --nav-height: 72px;

  --small-icon-size: 30px;
  --medium-icon-size: 60px;
  --large-icon-size: 100px;
}

* {
  font-family: BeVietnamPro, Sans-Serif;;

  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}


h2 {
  font-size: 128px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
  color: var(--secondaryColor);
  margin-bottom: 1vh;
  white-space: pre-wrap;
  text-transform: uppercase;
}

h4 {
  font-size: 64px;
  font-weight: 200;
  letter-spacing: 0em;
  text-align: center;
  color: var(--secondaryColor);
  margin-bottom: 0.5vh;
  white-space: pre-wrap;
  text-transform: uppercase;
}

p {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0em;
  color: var(--secondaryColor);
  text-align: left;

  margin-left: var(--margin);
  margin-right: var(--margin);
  white-space: pre-wrap;
}

li {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0em;
  color: var(--secondaryColor);
  text-align: left;

  margin-left: var(--margin);
  margin-right: var(--margin);
  white-space: pre-wrap;
}

a {
  text-decoration: none;
}

.flex-col {
  display: flex !important;
  flex-direction: column !important;
}
.flex-row {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}
.align-center {
  align-items: center;
}
.text-left {
 text-align: left;
}
.text-right {
  text-align: right;
}

.page {
  background: var(--mainColor);
  display: flex;
  flex-direction: column;
  align-content: center;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: var(--nav-height)
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 10vh;
}

.sub-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 5vh;
}

@keyframes hide{
  100% {
      color: transparent;
  }
}

.disapear {
  animation: disapear var(--scrollTime) forwards !important;
}
@keyframes disapear {
  0% {
      opacity: 100%;
  }
  100% {
      opacity: 0%;
      display: none;
  }
}
.appear {
  animation: appear var(--scrollTime) forwards;
}
@keyframes appear {
  0% {
      opacity: 0%;
  }
  100% {
      opacity: 100%;
  }
}

 .nav-right-margin {
  margin-right: var(--nav-margin);
}

.nav-left-margin {
  margin-left: var(--nav-margin);
}

 .right-margin {
  margin-right: var(--margin);
}

.left-margin {
  margin-left: var(--margin);
}


.icon-small { 
  fill: var(--mainColorLight);
  height: var(--small-icon-size);
  width: var(--small-icon-size);
}
.icon-medium { 
  fill: var(--mainColorLight);
  height: var(--medium-icon-size);
  width: var(--medium-icon-size);
}
.icon-large { 
  fill: var(--mainColorLight);
  height: var(--large-icon-size);
  width: var(--large-icon-size);
}
.icon:hover {
  fill: var(--highlight);
}