.stock-graph-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    border-radius: 1rem;
    overflow: hidden;

    /* TODO Fix it so we just turn the brightness of secondaryColor down */
    background-color: var(--secondaryColor);
    background:linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8));
}

.graph-title-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 24px;
    margin-bottom: 0px;
}

.graph-title {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: -0.025em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.time-picker {
    width: max-content;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: rgb(178,178,178);
    border-radius: 1rem;
    padding: 0.35rem 0.45rem;
}

.time-drop-down {
    display: flex;
    flex-direction: row;
}

.time-drop-down-option {
    margin: 0px 6px;
    /* TODO add small border */
}

@media (max-width: 686px) {
    .time-picker-wrapper {
        width: 79px;
        height: 32px;
        margin-left: 32px;
        display: flex;
        flex-direction: row;
        justify-content: right;
    }

    .time-picker {
        width: max-content;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        background-color: rgb(178,178,178);
        border-radius: 1rem;
        padding: 0.35rem 0.45rem;
        position: absolute;
    }

    .time-drop-down {
        display: flex;
        flex-direction: column;
    }

    .time-drop-down-option {
        margin: 0px 0.45px;
        /* TODO add small border */
    }
}

.graph-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 320px;
    margin-top: 48px;
}

.graph {
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
}

.stats-group-wrapper-wrapper {
    display: flex;
    align-items: flex-start;
    margin: 24px 24px 0px;
}

@media (max-width: 686px) {
    .stats-group-wrapper {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 6px;
    }
}
@media (min-width: 686px) {
    .stats-group-wrapper {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 6px;
    }
}
@media (min-width: 960px) {
    .stats-group-wrapper {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.stat-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 6px;
}

.stat-title-wrapper {
    display: flex;
    align-items: center;
}

.stat-title {
    font-weight: 500;
    color: var(--neutral);
    line-height: 1.25rem;
}

.stat-num-wrapper {
    display: flex;
    align-items: center;
    margin-top: 6px;
}

.stat-mainstat {
    font-size: 2.25rem;
    line-height: 1;
    font-weight: 700;
    letter-spacing: -0.025em;
}

.stat-substats-wrapper {
    margin-left: 6px;
}

.stat-substats-top-wrapper {
    display: flex;
    align-items: center;
}

.stat-substat {
    margin-left: 2px;
    font-weight: 500;
}

.stat-green {
    color: rgb(22 163 74);
}

.stat-red {
    color: rgb(220 38 38);
}

.stat-arrow {
    height: 20px;
    width: 20px;
}

.portfolio-picker-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1vmin;
    margin-top: 2vmin;
}

.portfolio-option {
    font-size: 1.125rem;
    color: var(--secondaryColor);
    margin: 0.35rem 1rem;
}

.underline-portfolio-chosen {
    background-color: var(--secondaryColor);
    height: 0.15rem;
}

.underline-portfolio-unchosen {
    height: 0.15rem;
}